import React, { useContext, useEffect, useState } from "react";
import LayoutComponent from "../components/shared/Layout";
import GameMenu from "../components/GameMenu";
import SportAccordian from "../components/SportAccordian";
import {
  Accordion,
  Button,
  Dropdown,
  DropdownButton,
  Form,
} from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { BiSortAlt2 } from "react-icons/bi";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import NoEvent from "../components/NoEvent";
import { io } from "socket.io-client";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import SearchBar from "../components/SearchBar";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty, startCase } from "lodash";
import { createPortal } from "react-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleRight, FaArrowsAltV } from "react-icons/fa";
import BackLay from "../components/BackLay";
import SportAccordianInner from "../components/SportAccordianInner";
import SportAccordianInnerFav from "../components/SportAccordianInnerFav";
import logo from "../assets/images/loader.gif";
import Skeletion from "../components/Skeletion";
import { Fade } from "react-awesome-reveal";
const Favourite = () => {
  const {
    setGameTab,
    gameTab,
    keyTime,
    setKeyTime,
    user,
    profileData,
    setSearchTab,
    searchTab,
    parly,
    setParly,
  } = useContext(AuthContext);
  const { betPlace } = useContext(BetSlipContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loader, setLoader] = useState(false);
  const [odds, setOdds] = useState({});
  const [list, setList] = useState([]);
  const [activeFav, setActiveFav] = useState([]);
  const [position, setPosition] = useState([]);
  const addToFavioute = async (
    id,
    favoriteType,
    favoriteMarketId,
    detail,
    market
  ) => {
    const { status, data: response_users } = await apiGet(
      apiPath.addToFavourite +
        `?eventId=${id}&favoriteType=${favoriteType}&favoriteMarketId=${favoriteMarketId}&eventType=${
          detail?.eventType
        }&market=${market ? market : ""}`
    );
    if (response_users?.success) {
      getData();
    }
  };
  const [ids, setIds] = useState({
    eventId: [],
    marketId: [],
  });
  const getData = async () => {
    setLoader(true);
    const { status, data } = await apiGet(apiPath.favourtieList);
    if (data?.success) {
      setList(
        data?.results?.sort(
          (a, b) => new Date(a.eventDateTime) - new Date(b.eventDateTime)
        )
      );
      let resultMatchOdds = data?.results?.map((res) => {
        return res?.eventId;
      });
      let resultMarket = data?.results?.map((res) => {
        return res?.marketId;
      });
      setIds({
        eventId: resultMatchOdds,
        marketId: resultMarket,
      });

      if (resultMatchOdds?.length > 0) {
        getBetPosition(resultMarket, resultMatchOdds);
        getMatchOddsMulti(resultMatchOdds, resultMarket);
      }
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const getMatchOddsMulti = async (id, idMarket) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOddsNew +
        `?eventId=${id?.join(",")}&marketId=${idMarket?.join(",")}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setOdds(socket_data);
      }
    }
  };

  useEffect(() => {
    if (betPlace?.isPlaced && betPlace?.type == "matched") {
      getBetPosition(ids?.marketId, ids?.eventId);
    }
  }, [betPlace, ids]);
  useEffect(() => {
    if (!isEmpty(user)) {
      getData();
    }
  }, [gameTab, keyTime, user]);

  useEffect(() => {
    if (ids?.eventId?.length > 0) {
      let time = setInterval(() => {
        getMatchOddsMulti(ids?.eventId, ids?.marketId);
      }, 2000);
      return () => {
        clearInterval(time);
      };
    }
  }, [ids]);

  const getBetPosition = async (item, eventId) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?eventIds=${eventId}&multi=true&marketId=${item?.join(
          ","
        )}&isFavorite=true`
    );
    if (status === 200) {
      setPosition(response_users?.results);
    }
  };

  return (
    <div>
      <LayoutComponent>
        {loader ? (
          <Fade cascade duration={200}>
            <Skeletion />
          </Fade>
        ) : list?.length > 0 ? (
          <Fade cascade duration={1500}>
            <div className="bg-skylight p-3">
              {list?.length > 0 ? (
                list?.map((object, index) => {
                  return (
                    <SportAccordianInnerFav
                      index={index}
                      keyTime={keyTime}
                      matchOdds={
                        odds[object?.eventId]?.find((res) => {
                          return res?.type == "odds";
                        })?.data || {}
                      }
                      matchBookmaker={
                        odds[object?.eventId]?.find((res) => {
                          return res?.type == "bookmaker";
                        })?.data || {}
                      }
                      matchFancy={
                        odds[object?.eventId]
                          ?.find((res) => {
                            return res?.type == "fancy";
                          })
                          ?.data?.filter((item) => {
                            return item?.eventId == object?.eventId;
                          }) || {}
                      }
                      matchSportBook={
                        odds[object?.eventId]
                          ?.find((res) => {
                            return res?.type == "sportbook";
                          })
                          ?.data?.filter((item) => {
                            return item?.betfairEventId == object?.eventId;
                          }) || {}
                      }
                      object={object}
                      position={
                        position?.length > 0
                          ? position?.find((res) => {
                              return res?.eventId == object?.eventId;
                            }) || {}
                          : {}
                      }
                      activeFav={activeFav}
                      setActiveFav={setActiveFav}
                      ids={ids}
                      addToFavioute={addToFavioute}
                    />
                  );
                })
              ) : (
                <NoEvent />
              )}
            </div>
          </Fade>
        ) : (
          <Fade cascade duration={500}>
            <NoEvent />
          </Fade>
        )}
      </LayoutComponent>
    </div>
  );
};

export default Favourite;
