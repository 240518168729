import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import obj from "../Utils/helpers";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import BackLay from "./BackLay";
import AuthContext from "../context/AuthContext";
import { priceFormat } from "../Utils/constants";
import growth from "../assets/images/growth.svg";
import BattingIframe from "./BattingIframe";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SkeletonTheme } from "react-loading-skeleton";
import LiveMatchTracker from "../pages/Score/ScoreBoard";
import Loader from "./Loader";
import BackLayHome from "./BackLayHome";
import SportAccordianInner from "./SportAccordianInner";
import { FaRegStar } from "react-icons/fa";
import IconList from "./IconList";
import SportAccordianInnerSlip from "./SportAccordianInnerSlip";
import Slider from "react-slick";
import secureLocalStorage from "react-secure-storage";
const ByTimeSports = ({
  item,
  index,
  keyTime,
  activeKeys,
  handleSelect,
  type,
  score,
  activeFav,
  setActiveFav,
  odds,
  multiOddsPremium,
  matchOdds,
  object,
}) => {
  const { user, parly,setParlyBet, parlyBet,setMessage, message  } = useContext(AuthContext);
  const [premiumDetail, setPremiumDetail] = useState([]);
  const [premiumDetail2, setPremiumDetail2] = useState({});
  const addToFavioute = async (
    id,
    favoriteType,
    favoriteMarketId,
    eventType
  ) => {
    const { status, data: response_users } = await apiGet(
      apiPath.addToFavourite +
        `?eventId=${id}&favoriteType=${favoriteType}&favoriteMarketId=${favoriteMarketId}&eventType=${eventType}`
    );

    if (response_users?.success) {
      if (activeFav?.includes(id)) {
        setActiveFav((prev) => {
          return prev.filter((item) => {
            return item !== id;
          });
        });
      } else {
        setActiveFav((prev) => {
          return [...prev, id];
        });
      }
    }
  };

  const navigate = useNavigate();

  let betCheckObj = {
    4: "cricket",
    2: "tennis",
    1: "soccer",
    3: "Casino",
  };
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    cssEase: "linear",
  };
  useEffect(() => {
    if (parly) {
      if (betCheckObj[multiOddsPremium[0]?.eventType] == "soccer") {
        let obj1 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId && res?.marketName == "1x2"
            );
          }) || {};
        if (!isEmpty(obj1)) {
          setPremiumDetail(obj1);
        }
        let obj2 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName == "Total"
            );
          }) || {};
        if (!isEmpty(obj2)) {
          setPremiumDetail2(obj2);
        }
      } else if (betCheckObj[multiOddsPremium[0]?.eventType] == "tennis") {
        let obj1 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName?.includes("Winner")
            );
          }) || {};
        if (!isEmpty(obj1)) {
          setPremiumDetail(obj1);
        }
        let obj2 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName == "Total games"
            );
          }) || {};
        if (!isEmpty(obj2)) {
          setPremiumDetail2(obj2);
        }
      } else {
        let obj1 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName?.includes("Winner")
            );
          }) || {};
        if (!isEmpty(obj1)) {
          setPremiumDetail(obj1);
        }
        let obj2 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName == "Total"
            );
          }) || {};

        if (!isEmpty(obj2)) {
          setPremiumDetail2(obj2);
        } else {
          let newObj = multiOddsPremium?.filter((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              !res?.marketName?.includes("Winner")
            );
          });
          if (newObj?.length > 0) {
            setPremiumDetail2(newObj[0]);
          }
        }
      }
    }
  }, [parly, multiOddsPremium, type]);



  const handelPaly = (item, res) => {
   
    if (res?.odds >= 1.4){
    setParlyBet((current) => {
      let newObj = {
        ...item,
        sportsBookSelectionObject: res,
        selectObj: res?.id,
        newEventId: object?.eventId,
        eventName: object?.eventName,
        marketId: object?.marketId,
      };
      if (current?.length > 0) {
        if (
          current?.filter((temp) => {
            return temp?.newEventId == object?.eventId;
          })?.length > 0
        ) {
          if (
            current?.filter((temp) => {
              return temp?.newEventId != object?.eventId;
            })?.length > 0
          ) {
            secureLocalStorage.setItem("parlyBet", [
              ...current?.filter((temp) => {
                return temp?.newEventId != object?.eventId;
              }),
              newObj,
            ]);
            return [
              ...current?.filter((temp) => {
                return temp?.newEventId != object?.eventId;
              }),
              newObj,
            ];
          } else {
            secureLocalStorage.setItem("parlyBet", [newObj]);
            return [newObj];
          }
        } else {
          secureLocalStorage.setItem("parlyBet", [...current, newObj]);
          return [...current, newObj];
        }
      } else {
        secureLocalStorage.setItem("parlyBet", [newObj]);
        return [newObj];
      }
    });
  }else{
    setMessage({
      ...message,
      status: true,
      message: "Min Odds Alowed 1.4",
      errorType: "Error",
    });
  }
  };
  return (
    <div className="sport-accord-body" style={{ marginBottom: "1px" }}>
      <div className="position-relative">
        <div
         
          className={
            "d-flex justify-content-between align-items-center  bg-primary-odds p-1 lightgreen"
          }
        >
          <div
            className={`fave  bg-star-sprit-black  position-absolute start-0 ${
              activeFav?.includes(item?.eventId) ? "animate" : ""
            }`}
            style={{
              marginLeft: "-20px !important",
              marginTop: "-16px",
            }}
            onClick={() => {
              if (isEmpty(user)) {
                navigate("/login");
              } else {
                if (item?.hasMatchOdds) {
                  addToFavioute(
                    item?.eventId,
                    item?.marketId,
                    "Match Odds",
                    item?.eventType
                  );
                }
              }
            }}
          ></div>

          <div
            style={{
              width: "90%",
              color: "white",
              marginLeft: "4px",
              padding: "3px",
            }}
            onClick={() =>
              navigate(`/full-market/${item?.marketId}/${item?.eventId}`)
            }
            className={"ps-4 mt-0"}
          >
            <span style={{ fontWeight: "600", fontSize: "12px" }}>
              {item?.eventName}
            </span>{" "}
            <div
              className="half-table"
              style={{
                display: "flex",
                flex: "1",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
                paddingTop: "2px",
              }}
            >
              <IconList item={item} parly={parly} />
              <span
                className={`in-play ${
                  keyTime == "in_play"
                    ? "in-play"
                    : keyTime == "active"
                    ? keyTime
                    : "tommorow"
                }`}
              >
                {keyTime == "in_play"
                  ? "In-play"
                  : keyTime == "active"
                  ? `${obj?.matchDateTime(item?.eventDateTime, "same")}`
                  : obj?.dateFormat(item?.eventDateTime)}
              </span>{" "}
              {matchOdds?.totalMatched > 0 && (
                <>
                  <span
                    className="text-left"
                    style={{ fontSize: "10px", marginLeft: "4px" }}
                  >
                    Matched
                  </span>
                  <strong style={{ fontSize: "10px", marginLeft: "3px" }}>
                    {matchOdds?.totalMatched > 0 &&
                      `${priceFormat(matchOdds?.totalMatched)}`}
                  </strong>{" "}
                </>
              )}
            </div>
          </div>

          <div
            className="d-flex align-items-center"
            style={{ whiteSpace: `nowrap`, gap: `7px` }}
          >
            {score?.length > 0 &&
            !isEmpty(
              score?.find((res) => {
                return Number(res?.eventId) == Number(item?.eventId);
              })
            ) ? (
              <div
                className="counter dark-counter"
                style={{
                  fontSize: "11px",
                  fontWeight: "800",
                 
                }}
              >
                {
                  score?.find((res) => {
                    return Number(res?.eventId) == Number(item?.eventId);
                  })?.score
                }
              </div>
            ) : item?.outcome && item?.outcome != undefined ? (
              <div
                className="counter dark-counter"
                style={{
                  fontSize: "11px",
                  fontWeight: "800",
                  
                }}
              >
                {item?.outcome}
              </div>
            ) : (
              ""
            )}

            <Button className="bg-transparent text-white border-0 fs-4 px-2 pt-0 pe-0">
              <FaAngleRight />
            </Button>
          </div>
        </div>

        {parly && !isEmpty(premiumDetail) && (
          <div
            className="d-flex justify-content-between flex-column align-items-center lightgreen"
            style={{
             
              padding: `0.3rem`,
              paddingLeft: "0",
              paddingRight: "0",
            }}
          >
            <div className="w-100 d-flex align-items-center position-relative">
              <div
                style={{
                  height: "23px",
                 
                  width: "50%",
                  borderTopLeftRadius: "4px",
                }}
                className="parly-section-header"
              ></div>
              <div
                style={{
                  height: "23px",
                  
                  width: "50%",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
                className="parly-section-header"
              ></div>
            </div>
            <div className="d-flex w-100 h-100">
              <div
                style={{
                  
                  height: "auto",
                  color: "white",
                }}
                className="d-flex flex-column w-100 align-items-start p-2 paralydat"
              >
                <span
                  onClick={() => {
                    navigate(
                      `/full-market/${object?.marketId}/${object?.eventId}`
                    );
                  }}
                >
                  {object?.eventName}
                  <br />{" "}
                </span>
              </div>{" "}
              <div
                style={{
                
                  marginTop: "-22.4px",
                  paddingBottom: "16px",
                  borderTopRightRadius: "4px",
                }}
                className="d-flex flex-column w-50 h-100 align-items-start lightgreen"
              >
                <Slider className="w-100 h-100 parly-slider" {...settings}>
                  <div>
                    <div className="parly-list-page-outer">
                      <span
                        style={{
                          fontSize: ".7142857142857143rem",
                          width: "50%",
                        }}
                        className="d-flex justify-content-center"
                      >
                        {object?.gameType == "soccer" ? "1x2" : "Winner"}
                      </span>{" "}
                      <span
                        style={{
                          fontSize: ".7142857142857143rem",
                          width: "50%",
                          // paddingTop: "10px",
                        }}
                        className="d-flex justify-content-center"
                      >
                        O/U
                      </span>
                    </div>

                    <div className="parly-list-page">
                      <div
                        className={
                          !isEmpty(premiumDetail) &&
                          premiumDetail?.marketName?.includes("1x2")
                            ? "parly-list-page-inner parly-soccer"
                            : "parly-list-page-inner"
                        }
                      >
                        {!isEmpty(premiumDetail) &&
                        premiumDetail?.apiSiteStatus !== "ACTIVE" ? (
                          <span className="suspended">Suspended</span>
                        ) : premiumDetail?.marketStatus == 9 ||
                          premiumDetail?.marketStatus == 2 ||
                          premiumDetail?.marketStatus == 3 ||
                          premiumDetail?.marketStatus == 4 ? (
                          <span className="suspended">
                            {premiumDetail?.marketStatus == 9
                              ? "Ball Running"
                              : premiumDetail?.marketStatus == 2
                              ? "In Active"
                              : premiumDetail?.marketStatus == 3
                              ? "Suspended"
                              : premiumDetail?.marketStatus == 4
                              ? "Closed"
                              : "Suspended"}
                          </span>
                        ) : (
                          ""
                        )}
                        {premiumDetail?.sportsBookSelection?.length > 0 ? (
                          <>
                            {premiumDetail?.sportsBookSelection
                              ?.slice(0, 3)
                              ?.map((res) => {
                                let obj = parlyBet?.find((item) => {
                                  return (
                                    item?.marketName ==
                                      premiumDetail?.marketName &&
                                    item?.sportsBookSelectionObject?.odds ==
                                      res?.odds
                                  );
                                });
                                let oldObject =
                                  premiumDetail?.oldSportsBookSelection?.find(
                                    (obj) => {
                                      return obj?.id == res?.id;
                                    }
                                  ) || {};
                                return (
                                  <SportAccordianInnerSlip
                                    premiumDetail={premiumDetail}
                                    handelPaly={handelPaly}
                                    obj={obj}
                                    res={res}
                                    oldObject={oldObject}
                                  />
                                );
                              })}
                          </>
                        ) : (
                          <>
                            <div>
                              <span></span>
                              <strong></strong>
                            </div>
                            <div>
                              <span></span>
                              <strong></strong>
                            </div>
                          </>
                        )}{" "}
                      </div>
                      <div
                        className={
                          !isEmpty(premiumDetail2) &&
                          premiumDetail2?.marketName?.includes("1x2")
                            ? "parly-list-page-inner parly-soccer"
                            : "parly-list-page-inner"
                        }
                      >
                        {!isEmpty(premiumDetail2) &&
                        premiumDetail2?.apiSiteStatus !== "ACTIVE" ? (
                          <span className="suspended">Suspended</span>
                        ) : premiumDetail2?.marketStatus == 9 ||
                          premiumDetail2?.marketStatus == 2 ||
                          premiumDetail2?.marketStatus == 3 ||
                          premiumDetail2?.marketStatus == 4 ? (
                          <span className="suspended">
                            {premiumDetail2?.marketStatus == 9
                              ? "Ball Running"
                              : premiumDetail2?.marketStatus == 2
                              ? "In Active"
                              : premiumDetail2?.marketStatus == 3
                              ? "Suspended"
                              : premiumDetail2?.marketStatus == 4
                              ? "Closed"
                              : "Suspended"}
                          </span>
                        ) : (
                          ""
                        )}
                        {premiumDetail2?.sportsBookSelection?.length > 0 ? (
                          <>
                            {premiumDetail2?.sportsBookSelection
                              ?.slice(0, 3)
                              ?.map((res) => {
                                let obj = parlyBet?.find((item) => {
                                  return (
                                    item?.marketName ==
                                      premiumDetail2?.marketName &&
                                    item?.sportsBookSelectionObject?.odds ==
                                      res?.odds
                                  );
                                });
                                let oldObject =
                                  premiumDetail2?.oldSportsBookSelection?.find(
                                    (obj) => {
                                      return obj?.id == res?.id;
                                    }
                                  ) || {};
                                return (
                                  <SportAccordianInnerSlip
                                    premiumDetail={premiumDetail2}
                                    handelPaly={handelPaly}
                                    obj={obj}
                                    res={res}
                                    oldObject={oldObject}
                                  />
                                );
                              })}
                          </>
                        ) : (
                          <>
                            <div>
                              <span></span>
                              <strong></strong>
                            </div>
                            <div>
                              <span></span>
                              <strong></strong>
                            </div>
                          </>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="parly-list-page-outer">
                      <span
                        style={{
                          fontSize: ".7142857142857143rem",
                          width: "50%",
                        }}
                        className="d-flex justify-content-center"
                      >
                        Away Inn O/U
                      </span>{" "}
                      <span
                        style={{
                          fontSize: ".7142857142857143rem",
                          width: "50%",
                        }}
                        className="d-flex justify-content-center"
                      >
                        Total 6 O/U
                      </span>
                    </div>
                    <div className="parly-list-page">
                      <>
                        <div>
                          <span></span>
                          <strong></strong>
                        </div>
                        <div>
                          <span></span>
                          <strong></strong>
                        </div>
                      </>
                      <>
                        <div>
                          <span></span>
                          <strong></strong>
                        </div>
                        <div>
                          <span></span>
                          <strong></strong>
                        </div>
                      </>
                      {/* // )} */}
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ByTimeSports;
