import React,{  useEffect ,useState  } from "react";
import { Fade } from "react-awesome-reveal";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletionDetail = () => {
  const [getBase, setBase] = useState("");
      const [border, setBorder] = useState("");
    
      useEffect(() => {
       if(localStorage.getItem('colorTheme')=="dark-theme"){
        setBase("#fff");
        
       }else{
        setBase("#064b4d");
       }
      }, [localStorage.getItem('colorTheme')]);
  return (
    <Fade cascade duration={500}>
      <div style={{ marginTop: "50px" }}>
        <SkeletonTheme baseColor={getBase}>
          <div className="p-2">
            <div
              style={{  borderRadius: "5px" }}
              className="skeletion-new"
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{
                  borderBottom: "2px solid #064b4d",
                  padding: "6px",
                  marginBottom: "4px",
                }}
              >
                <div className="d-flex">
                  <Skeleton
                    circle
                    height={12}
                    width={12}
                    style={{ marginRight: "10px" }}
                  />
                  <Skeleton height={12} width={120} />{" "}
                </div>{" "}
                <Skeleton
                  circle
                  height={12}
                  width={12}
                  style={{ marginRight: "10px" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "7px",
                  paddingBottom: "0",
                  paddingTop: "3px",
                }}
              >
                <div style={{ width: "60%" }}>
                  <Skeleton height={12} width={"100%"} />{" "}
                  <Skeleton height={12} width={"50%"} />{" "}
                  <Skeleton height={12} width={"100%"} />{" "}
                  <Skeleton height={12} width={"50%"} />
                </div>
                <div style={{ width: "38%" }}>
                  <Skeleton height={35} width={"100%"} />
                  <Skeleton height={35} width={"100%"} />
                </div>
              </div>
              <div
                className="d-flex justify-content-end"
                style={{
                  padding: "7px",
                  paddingTop: "0",
                  paddingBottom: "3px",
                }}
              >
                <Skeleton
                  circle
                  height={11}
                  width={11}
                  style={{ marginRight: "10px" }}
                />
                <Skeleton height={11} width={120} />{" "}
              </div>
            </div>
          </div>
        </SkeletonTheme>{" "}
        <SkeletonTheme baseColor={getBase}>
          <div className="p-2">
            <div
              style={{  borderRadius: "5px" }}
              className="skeletion-new"
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{
                  borderBottom: "2px solid #064b4d",
                  padding: "6px",
                  marginBottom: "4px",
                }}
              >
                <div className="d-flex">
                  <Skeleton
                    circle
                    height={12}
                    width={12}
                    style={{ marginRight: "10px" }}
                  />
                  <Skeleton height={12} width={120} />{" "}
                </div>{" "}
                <Skeleton
                  circle
                  height={12}
                  width={12}
                  style={{ marginRight: "10px" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "7px",
                  paddingBottom: "0",
                  paddingTop: "3px",
                }}
              >
                <div style={{ width: "60%" }}>
                  <Skeleton height={12} width={"100%"} />{" "}
                  <Skeleton height={12} width={"50%"} />{" "}
                  <Skeleton height={12} width={"100%"} />{" "}
                  <Skeleton height={12} width={"50%"} />
                </div>
                <div style={{ width: "38%" }}>
                  <Skeleton height={35} width={"100%"} />
                  <Skeleton height={35} width={"100%"} />
                </div>
              </div>
              <div
                className="d-flex justify-content-end"
                style={{
                  padding: "7px",
                  paddingTop: "0",
                  paddingBottom: "3px",
                }}
              >
                <Skeleton
                  circle
                  height={11}
                  width={11}
                  style={{ marginRight: "10px" }}
                />
                <Skeleton height={11} width={120} />{" "}
              </div>
            </div>
          </div>
        </SkeletonTheme>{" "}
        <SkeletonTheme baseColor={getBase}>
          <div className="p-2">
            <div
              style={{  borderRadius: "5px" }}
              className="skeletion-new"
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{
                  borderBottom: "2px solid #064b4d",
                  padding: "6px",
                  marginBottom: "4px",
                }}
              >
                <div className="d-flex">
                  <Skeleton
                    circle
                    height={12}
                    width={12}
                    style={{ marginRight: "10px" }}
                  />
                  <Skeleton height={12} width={120} />{" "}
                </div>{" "}
                <Skeleton
                  circle
                  height={12}
                  width={12}
                  style={{ marginRight: "10px" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "7px",
                  paddingBottom: "0",
                  paddingTop: "3px",
                }}
              >
                <div style={{ width: "60%" }}>
                  <Skeleton height={12} width={"100%"} />{" "}
                  <Skeleton height={12} width={"50%"} />{" "}
                  <Skeleton height={12} width={"100%"} />{" "}
                  <Skeleton height={12} width={"50%"} />
                </div>
                <div style={{ width: "38%" }}>
                  <Skeleton height={35} width={"100%"} />
                  <Skeleton height={35} width={"100%"} />
                </div>
              </div>
              <div
                className="d-flex justify-content-end"
                style={{
                  padding: "7px",
                  paddingTop: "0",
                  paddingBottom: "3px",
                }}
              >
                <Skeleton
                  circle
                  height={11}
                  width={11}
                  style={{ marginRight: "10px" }}
                />
                <Skeleton height={11} width={120} />{" "}
              </div>
            </div>
          </div>
        </SkeletonTheme>
      </div>
    </Fade>
  );
};

export default SkeletionDetail;
