import React, { useState,useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import gif from "../assets/images/image_95581.gif";
import lottery from "../assets/images/lottery.mp4";
import ReactPlayer from "react-player";
import AuthContext from "../context/AuthContext";
import { isEmpty, toLower } from "lodash";
const FooterSection = () => {
  const { t } = useTranslation();
  const [showImg, setShowImg] = useState(true);
  const navigate = useNavigate();
  let { user } = useContext(AuthContext);
  return (
    <>
      {/* {showImg &&
      <div className="hot-game float-banner">
      <a className="close" onClick={()=>setShowImg(false)}>✖</a>
        <img src={gif} alt="" onClick={() => navigate("/all-casino/hot")}/>
      </div>
      } */}
      <div class="pay">
        {/* {isEmpty(user) && */}
        <ReactPlayer
          muted={false}
          controls={true}
          playsinline={true}
          playing={false}
          width="100%"
          height="auto"
          url={"https://www.youtube.com/watch?v=INc8ae-3G0k"}
          config={{
            file: {
              attributes: {
                preload: "none",
                //forceHLS: true,
              },
              hlsOptions: {
                //autoStartLoad: false,
                startLevel: 3,
              },
            },
          }}
        />
{/* } */}
        <div class="payment-inner gaming-lic">
          <div class="payment-row">
            <h2>{t("Gaming License")}</h2>
            <ul>
              <li>
                <Link to="/">
                  <img src="../assets/images/gaming_license.png" alt="" />
                </Link>
              </li>
            </ul>
          </div>
          <div class="payment-row">
            <h2>{t("Responsible_Gaming")}</h2>
            <ul>
              <li>
                <Link to="/">
                  <img src="../assets/images/age-limit.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="../assets/images/regulations.svg" alt="" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src="../assets/images/gamcare.svg" alt="" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <h2>{t("Payment_Methods")}</h2>
        <ul className="paymentm">
          <li>
            <img src="../assets/images/pay1.png" alt="" />
          </li>
          <li>
            <img src="../assets/images/pay2.png" alt="" />
          </li>
          <li>
            <img src="../assets/images/pay3.png" alt="" />
          </li>
          <li>
            <img src="../assets/images/pay4.png" alt="" />
          </li>
          <li>
            <img src="../assets/images/pay5.png" alt="" />
          </li>
          <li>
            <img src="../assets/images/pay6.png" alt="" />
          </li>
          <li>
            <img src="../assets/images/pay7.png" alt="" />
          </li>
          <li>
            <img src="../assets/images/pay8.png" alt="" />
          </li>
          <li>
            <img src="../assets/images/pay9.png" alt="" />
          </li>
          <li>
            <img src="../assets/images/pay10.png" alt="" />
          </li>
        </ul>
        <div class="language-sec">
          <h6>
            <Link to="/info/about-us">{t("About_Us")}</Link>
          </h6>
          <h6>
            <Link to="/info/privacy-policy">{t("Privacy_Policy")}</Link>
          </h6>
          <h6>
            <Link to="/info/tnc">{t("Terms_Condition")}</Link>
          </h6>
          <h6>
            <Link to="/info/rule-regulation">{t("Rule_Regulations")}</Link>
          </h6>
          <h6>
            <Link to="/info/responsible-gaming">{t("Responsible_Gaming")}</Link>
          </h6>
          <h6>
            <Link to="/info/faq">{t("FAQ")}</Link>
          </h6>
          <h6>
            <Link to="/info/contact-us">{t("Contact_Us")}</Link>
          </h6>

          {/* <h6>
          <Link to="/affilate">{t("Affiliate")}</Link>
        </h6> */}
          <h6>
            <Link to="/">{t("bajifair_Blog")}</Link>
          </h6>
        </div>
      </div>
    </>
  );
};

export default FooterSection;
