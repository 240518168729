import React, { useEffect, useState } from "react";
import { GiSoccerBall, GiTennisBall, GiTrophy } from "react-icons/gi";
import { AiFillTrophy } from "react-icons/ai";
import { isEmpty } from "lodash";
import { FaStar } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const GameMenu = ({ game, setGame, count,keyTime }) => {
  // useEffect(() => {
  //   if (game) {
  //     setGame("cricket");
  //   }
  // }, []);
  const navigate = useNavigate();
  return (
    <div className="game-menu">
      <div className="w-100 h-100 d-flex justify-content-between align-items-center">
        <ul className="p-0 mb-0 w-100 game-menu-inner  d-flex justify-content-between align-items-center">
          <li
            id={"casino"}
            onClick={() => {
              setGame("casino");
              navigate("/casino");
            }}
            className={game == "casino" ? "active" : ""}
            style={{
              color: `#f22c8f`,
              backgroundImage:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.100524) 0%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.35) 100%)",
              marginLeft: "1px",
            }}
          >
            <div>
              <img src="../assets/images/chip.webp" style={{ width: `29px` }} />
            </div>
            Casino
          </li>
          <li
            id={"myFav"}
            onClick={() => {
              setGame("myFav");
            }}
            className={game == "myFav" ? "active" : ""}
          >
            <div>
              <FaStar />
            </div>
            My Fav
          </li>{" "}
          {
            keyTime!=="leagues" &&
            <li
            id={"all"}
            onClick={() => {
              setGame("all");
            }}
            className={game == "all" ? "active" : ""}
          >
            <div>
              {/* <i class="icon-cricket" style={{ fontSize: `18px` }}></i> */}
              <GiTrophy />
            </div>
            <span>
              {count?.cricketInplayCount +
                count?.soccerInplayCount +
                count?.tennisInplayCount}
            </span>
            All
          </li>
          }
          
          <li
            id={"cricket"}
            onClick={() => {
              setGame("cricket");
            }}
            className={game == "cricket" ? "active" : ""}
          >
            <div>
              <i class="icon-cricket" style={{ fontSize: `18px` }}></i>
            </div>
            <span>{count?.cricketInplayCount}</span>
            Cricket
          </li>
          <li
            id={"soccer"}
            onClick={() => {
              setGame("soccer");
            }}
            className={game == "soccer" ? "active" : ""}
          >
            <div>
              <GiSoccerBall />
            </div>
            <span>{count?.soccerInplayCount}</span>
            Soccer
          </li>
          <li
            id={"tennis"}
            onClick={() => {
              setGame("tennis");
            }}
            className={game == "tennis" ? "active" : ""}
          >
            <div>
              <GiTennisBall />
            </div>
            <span>{count?.tennisInplayCount}</span>
            Tennis
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GameMenu;
