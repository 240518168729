import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineDownload } from "react-icons/ai";
import PDF from "../assets/Premium_Cricket.pdf";
const Info = ({ handleShowInfo, sportRules }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="info-detail overflow-hidden">
      <div className="title position-relative">
        <h4>{sportRules ? "Rules of Sportbook" : "Fancybet Rule"}</h4>
        <Button
          className="bg-transparent border-0 position-absolute end-0 top-0 pt-1 fs-4 pe-3"
          onClick={() => handleShowInfo()}
        >
          <RxCross2 />
        </Button>
      </div>
      <div className={sportRules ? "info-body pt-0" : "info-body pt-sm-5 pt-3"}>
        {sportRules ? (
          <ul className="p-0 m-0 sport-rules-sec">
            <li>
              <span>Cricket</span>
              <Button href={PDF} target="_blank" className="bg-yellow">
                <AiOutlineDownload />
              </Button>
            </li>
          </ul>
        ) : (
          <div class="modal-content px-7 pt-4 leading-6 rounded-t-lg bg-white overflow-y-scroll">
            <ol class="pl-3.5 mb-4">
              <li class="list-decimal text-base">
                All Fancy Bets Will Be Validated When Match Has Been Tied.{" "}
                <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                All Advance Fancy Will Be Suspended Before Toss Or Weather
                Condition. <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                In Case Technical Error Or Any Circumstances Any Fancy Is
                Suspended And Does Not Resume Result Will Be Given All Previous
                Bets Will Be Valid. <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                If Any Case Wrong Rate And Wrong Commentary Has Been Given In
                Fancy That Particular Bets Will Be Cancelled.{" "}
                <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                In Any Circumstances Management Decision Will Be Final Related
                To All Exchange Items. <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                In Case Customer Make Bets In Wrong Fancy We Are Not Liable To
                Delete, No Changes Will Be Made And Bets Will Be Consider As
                Confirm Bet. <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                Due To Any Technical Error Market Is Open And Result Has Came
                All Bets After Result Will Be Deleted.{" "}
                <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                Four, Sixes, Wide, Wicket, Extra Run, Total Run, Highest Over
                And Top Batsman,Maiden Over,Caught-Out,No-Ball,Run-Out,Fifty And
                Century Are Valid Only Match Has Been Completed In Case Due To
                Rain Over Has Been Reduced All Other Fancy Will Be Deleted.All
                Complet Event Are Valid E.X 1 Over Run Team A Etc.. .{" "}
                <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                ODI / T10 / T20 / TEST Penalty Runs Will Be Counted{" "}
                <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                Due To Mistakes In Official Scorecard Our Scorecard And Results
                Will Be Valid. <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                Minimum Number Of Legal Over Has To Be Bowled By The Bowler
                Mentioned In The Event, Else The Same Will Not Be Considered As
                Valid. <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                Mystery Free Hit Boundaries Will Not Counted In Player
                Boundaries... <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                PLAYER BOOKMAKER{" "}
                <ul class="pl-3.5 py-1">
                  <li class="font-base">
                    Suppose Either No1 Or No2 Player Score Compare To Run But
                    Which Ever IS Higher Score IS Win
                  </li>
                  <li class="font-base">
                    Explaination Of Player Bookmaker: Compare Both Player Runs
                    At Wicket ..If V Kohli Runs 22 And R.Sharma Run 20 At The
                    Moment Of WICKET Then V KOHLI Will BE Winner.
                  </li>
                  <li class="font-base">
                    If Both Player Score Is Level It's Means Virat Khohali &amp;
                    Rohit Sharma Both Are Either Out Match Over Is Complete,
                    It's Mean Game Is Tie
                  </li>
                  <li class="font-base">
                    If Match Are Interpreted Due To Bed Weather Or Any Thing
                    Else At That Time Law Score Player Run Are Not Count And
                    Match Are Declared To ABOUNDED
                  </li>
                </ul>
              </li>
              <li class="list-decimal text-base">
                CHEATING{" "}
                <ul class="pl-3.5 py-1">
                  <li class="font-base">
                    Cheating Of Any Kind Is Not Allowed And Customers Bets Who
                    Are Deemed To Be Cheating Are Liable To Have Bets Made Void.
                    Cheating Includes But Is Not Limited To; Market Price
                    Manipulation, Court Siding, Sniping And Commission Abuse.
                  </li>
                  <li class="font-base">
                    The Site Reserves The Right To Void Any Bets That Are Under
                    Review As Part Of Any Integrity Investigation.
                  </li>
                  <li class="font-base">
                    The Site May Void Certain Bets In A Market Or Void A Whole
                    Market In Its Entirety As A Result Of Any Integrity
                    Investigation.
                  </li>
                  <li class="font-base">
                    The Site's Decision In Such Integrity Cases Shall Be ﬁnal
                    And Binding Upon The Customer.
                  </li>
                  <li class="font-base">
                    Customers Using VPN Applications To Mask Location Or Proxy
                    Servers To Mask Device Are Liable To Having Bets
                    Invalidated. Customers Appearing From Multiple IP Locations
                    Are Also Liable To Having Bets Invalidated.
                  </li>
                </ul>
              </li>
              <li class="list-decimal text-base">
                TEST{" "}
                <ul class="pl-3.5 py-1">
                  <li class="font-base">1 Session:-</li>
                  <li class="font-base">Complete Session Valid In Test.</li>
                  <li class="font-base">
                    Session Is Not Completed For Ex:- India 60 Over Run Session
                    Ind Is Running In Case India Team Declares Or All-Out At 55
                    Over Next 5 Over Session Will Be Continue In England Inning.
                  </li>
                  <li class="font-base">
                    1st Day 1st Session Run Minimum 25 Over Will Be Played Then
                    Result Is Given Otherwise 1st Day 1st Session Will Be
                    Deleted.
                  </li>
                  <li class="font-base">
                    1st Day 2nd Session Run Minimum 25 Over Will Be Played Then
                    Result Is Given Otherwise 1st Day 2nd Session Will Be
                    Deleted.
                  </li>
                  <li class="font-base">
                    1st Day Total Run Minimum 80 Over Will Be Played Then Result
                    Is Given Otherwise 1st Day Total Run Will Be Deleted.
                  </li>
                  <li class="font-base">
                    Test Match Both Advance Session Is Valid.
                  </li>
                  <li class="font-base">2. Test Lambi/ Inning Run:-</li>
                  <li class="font-base">
                    2.1 Mandatory 70 Over Played In Test Lambi Paari/ Innings
                    Run. If Any Team All-Out Or Declaration Lambi Paari/ Innings
                    Run Is Valid.
                  </li>
                  <li class="font-base">
                    In Case Due To Weather Situation Match Has Been Stopped All
                    Lambi Trades Will Be Deleted.
                  </li>
                  <li class="font-base">
                    In Test Both Lambi Paari / Inning Run Is Valid In Advance
                    Fancy.
                  </li>
                  <li class="font-base">3. Test Batsman:-</li>
                  <li class="font-base">
                    In Case Batsmen Is Injured He/She Is Made 34 Runs The Result
                    Will Be Given 34 Runs.
                  </li>
                  <li class="font-base">
                    Batsman 50/100 Run If Batsman Is Injured Or Declaration The
                    Result Will Be Given On Particular Run.
                  </li>
                  <li class="font-base">
                    In Next Men Out Fancy If Player Is Injured Particular Fancy
                    Will Be Deleted.
                  </li>
                  <li class="font-base">
                    In Advance Fancy Opening Batsmen Is Only Valid If Same
                    Batsmen Came In Opening The Fancy Will Be Valid In Case One
                    Batsmen Is Changed That Particular Player Fancy Will Be
                    Deleted.
                  </li>
                  <li class="font-base">
                    Test Match Both Advance Fancy Batsmen Run Is Valid.
                  </li>
                  <li class="font-base">4. Other Fancy Advance (Test):-</li>
                  <li class="font-base">
                    Four, Sixes, Wide, Wicket, Extra Run, Total Run, Highest
                    Over And Top Batsmen Is Valid Only If 300 Overs Has Been
                    Played Or The Match Has Been Won By Any Team Otherwise All
                    These Fancy Will Be Deleted.
                  </li>
                  <li class="font-base">5. Concussion In Test:-</li>
                  <li class="font-base">
                    All Bets Of One Over Session Will Be Deleted In Test
                    Scenario, In Case Session Is Incomplete. For Example Innings
                    Declared Or Match Suspended To Bad Light Or Any Other
                    Conditions.
                  </li>
                  <li class="font-base">Total Match- Events (Test):-</li>
                  <li class="font-base">
                    Minimum Of 300 Overs To Be Bowled In The Entire Test Match,
                    Otherwise All Bets Related To The Particular Event Will Get
                    Void. For Example, Total Match Caught Outs Will Be Valid
                    Only If 300 Overs Been Bowled In The Particular Test Match
                  </li>
                </ul>
              </li>
              <li class="list-decimal text-base">
                ODI / T20{" "}
                <ul class="pl-3.5 py-1">
                  <li class="font-base">1. Session:-</li>
                  <li class="font-base">
                    Match 1st Over Run Advance Fancy Only 1st Innings Run Will
                    Be Counted.
                  </li>
                  <li class="font-base">
                    For Example:- 35 Over Run Team A Is Playing Any Case Team A
                    Is All-Out In 33 Over Team A Has Made 150 Run The Session
                    Result Is Validated On Particular Run.
                  </li>
                  <li class="font-base">
                    Advance Session &amp; All Fancy Is Valid In Only 1st
                    Innings.
                  </li>
                  <li class="font-base">2. 50 Over Runs:-</li>
                  <li class="font-base">
                    2.1. In Case 50 Over Is Not Completed All Bet Will Be
                    Deleted Due To Weather Or Any Condition.
                  </li>
                  <li class="font-base">
                    Advance 50 Over Runs Is Valid In Only 1st Innings.
                  </li>
                  <li class="font-base">3. Odi / T20 Batsman Runs:-</li>
                  <li class="font-base">
                    In Case Batsman Is Injured He/She Is Made 34 Runs The Result
                    Will Be Given 34 Runs.
                  </li>
                  <li class="font-base">
                    In Next Men Out Fancy If Player Is Injured Particular Fancy
                    Will Be Deleted.
                  </li>
                  <li class="font-base">
                    In Advance Fancy Opening Batsmen Is Only Valid If Same
                    Batsmen Came In Opening The Fancy Will Be Valid In Case One
                    Batsmen Is Changed That Particular Player Fancy Will Be
                    Deleted.
                  </li>
                  <li class="font-base">4. T20 Over Runs:-</li>
                  <li class="font-base">
                    Advance 20 Over Run Is Valid Only In 1st Innings. 20 Over
                    Run Will Not Be Considered As Valid If 20 Overs Is Not
                    Completed Due To Any Situation.
                  </li>
                  <li class="font-base">5. T20 / ODI Batsman Runs:-</li>
                  <li class="font-base">
                    In Case Batsman Is Injured He/She Is Made 34 Runs The Result
                    Will Be Given 34 Runs.
                  </li>
                  <li class="font-base">
                    In Next Men Out Fancy If Player Is Injured Particular Fancy
                    Will Be Deleted.
                  </li>
                  <li class="font-base">
                    In Advance Fancy Opening Batsmen Is Only Valid If Same
                    Batsmen Came In Opening The Fancy Will Be Valid In Case One
                    Batsmen Is Changed That Particular Player Fancy Will Be
                    Deleted.
                  </li>
                  <li class="font-base">
                    6. At The Moment Of The Third Umpire Decision We Can't
                    Accept Bets In Any Fancy And Match Odds.
                  </li>
                </ul>
              </li>
              <li class="list-decimal text-base">
                OTHER FANCY{" "}
                <ul class="pl-3.5 py-1">
                  <li class="font-base">
                    T-20 ,One Day And Test Match In Case Current Innings Player
                    And Partnership Are Running In Between Match Has Been Called
                    Off Or Abandoned That Situation All Current Player And
                    Partnership Results Are Valid.
                  </li>
                  <li class="font-base">
                    1st Wicket Lost To Any Team Balls Meaning That Any Team 1st
                    Wicket Fall Down In How Many Balls That Particular Fancy At
                    Least Minimum One Ball Have To Be Played Otherwise Bets Will
                    Be Deleted.
                  </li>
                  <li class="font-base">
                    1st Wicket Lost To Any Team Fancy Valid Both Innings.
                  </li>
                  <li class="font-base">
                    How Many Balls For 50 Runs Any Team Meaning That Any Team
                    Achieved 50 Runs In How Many Balls That Particular Fancy At
                    Least One Ball Have To Be Played Otherwise That Fancy Bets
                    Will Be Deleted.
                  </li>
                  <li class="font-base">
                    How Many Balls For 50 Runs Fancy Any Team Only First Inning
                    Valid.
                  </li>
                  <li class="font-base">
                    How Many Balls Face Any Batsman Meaning That Any Batsman How
                    Many Balls He/She Played That Particular Fancy At Least One
                    Ball Have To Be Played Otherwise That Fancy Bets Will Be
                    Deleted.
                  </li>
                  <li class="font-base">
                    How Many Balls Face By Any Batsman Both Innings Valid.
                  </li>
                  <li class="font-base">
                    Dot Balls Event... Only No Run Will Count As Dot Ball. If
                    Wicket Means That Will Not Count As Dot Ball.
                  </li>
                </ul>
              </li>
              <li class="list-decimal text-base">
                LUNCH FAVOURITE{" "}
                <ul class="pl-3.5 py-1">
                  <li class="font-base">
                    The Team Which Is Favourite At Lunch Will Be Considered As
                    Lunch Favourite Or The Team Which Is Favourite After First
                    Inning Last Ball Will Be Considered As Lunch Favourite In
                    Our Exchange.
                  </li>
                  <li class="font-base">
                    In Any Circumstances Management Decision Will Be Final.
                  </li>
                  <li class="font-base">
                    In Case Of Tie In T20 Or One Day In Lunch Favourite Game ,
                    All Bets Will Be Deleted In Our Exchange.
                  </li>
                  <li class="font-base">
                    In Case Overs Are Reduced In A Match, The Team Which
                    Favourite At Lunch Will Be Considered As Lunch Favourite.
                  </li>
                  <li class="font-base">
                    For Example :- If Match Is Reduced To 18 Over Per Side In
                    T20 Or Oneday Then After 18 Over The Team Which Is Favourite
                    At Lunch Will Be Considered As Lunch Favourite.
                  </li>
                  <li class="font-base">
                    In Case Of Weather, 1st Innings Match Overs Are Reduced And
                    Direct Target Is Given To Team Which Will Bat In 2nd Inning
                    Then Lunch Favourite Will Be Considered After Target Is
                    Given At Lunch.
                  </li>
                  <li class="font-base">
                    For Example :- In T20 Match Rain Comes At 14 Over And Match
                    Is Interrupted Due To Rain And Direct Target Is Given To 2nd
                    Batting Team, Then Team Which Is Favourite In Match Odds
                    After Target Is Given In Match, Will Be Considered As Lunch
                    Favourite.
                  </li>
                </ul>
              </li>
              <li class="list-decimal text-base">
                KHADO{" "}
                <ul class="pl-3.5 py-1">
                  <li class="font-base">
                    You Can Choose Your Own Value In This Event.
                  </li>
                </ul>
              </li>
              <li class="list-decimal text-base">
                PLAYER RACE RULES{" "}
                <ul class="pl-3.5 py-1">
                  <li class="font-base">
                    All profits will be calculated @ .97
                  </li>
                  <li class="font-base">
                    Opposite batsman must atleast face 1 delivery in the innings
                    or bets will be voided. (only for player run race) bets will
                    only be considered valid if Full both innings are played or
                    the team is all out.
                  </li>
                  <li class="font-base">
                    Bets will be voided if for any reason the overs of the match
                    are reduced in any innings during the match except for
                    player run race and/or if both the bowlers aginst each other
                    have bowled full overs. (e.g., bumrah v rashid &amp; both
                    have bowled 4 overs/each in a t20 before the overs get
                    reduced
                  </li>
                  <li class="font-base">
                    If both players (batsman/bowler/wicket keeper/team) score is
                    equal then the result will be tied. the highest scoring
                    player (batsman/bowler/wicket keeper/team) will be
                    considered winner and diferrence (run/wicket/catcheswill be
                    calculated accordingly the bonus points given
                    (player/bowler/wicket keeper/team) will be considered and
                    the difference will be calculated accordingly
                  </li>
                </ul>
              </li>
              <li class="list-decimal text-base">
                Total Match 30s: In this market, If player's score between 30 to
                49 Runs that will be considered in this Market. If a player
                reached 50 or above, they aren't considerd in this event.{" "}
                <ul class="pl-3.5 py-1"></ul>
              </li>
              <li class="list-decimal text-base">
                If A Batsman Scores 100 Runs or More Then It's Counted In The
                Fifty Fancy <ul class="pl-3.5 py-1"></ul>
              </li>
            </ol>
          </div>
        )}
      </div>
    </div>
  );
};

export default Info;
