import React from "react";

const IconList = ({ item, parly }) => {
  return (
    <div className="icon-list-new">
      <i className="icon-virtual icon" />
      {/* {!item?.eventName.includes(" SRL") && Number(item?.channel) > 0 && (
        <img src="../assets/images/casino-icon/youtube.png" alt="" />
      )} */}
      {!parly && (
        <>
          {!item?.eventName.includes(" SRL") && (
            <>
              {item?.hasFancy && (
                <img src="../assets/images/casino-icon/f.png" alt="" />
              )}
              {item?.hasBookmaker && (
                <img src="../assets/images/casino-icon/b.png" alt="" />
              )}
            </>
          )}

          {(item?.eventType == 4 || item?.hasSportBook) && (
            <img src="../assets/images/casino-icon/s.png" alt="" />
          )}
        </>
      )}
    </div>
  );
};

export default IconList;
