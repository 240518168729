import React,{  useEffect ,useState  } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Skeletion = () => {
    const [getBase, setBase] = useState("");
    const [getBorder, setBorder] = useState("");
  
    useEffect(() => {
     if(localStorage.getItem('colorTheme')=="dark-theme"){
      setBase("#fff");
      setBorder("#fff")
     }else{
      setBase("#064b4d");
      setBorder("#064b4d")
     }
    }, [localStorage.getItem('colorTheme')]);
  return (
    <div>
      <SkeletonTheme baseColor={getBase}>
        <div className="p-2">
          <div
            style={{ borderRadius: "5px" }}
            className="skeletion-new p-3"
          >
            <div className="d-flex mb-1">
              <Skeleton
                circle
                height={12}
                width={12}
                style={{ marginRight: "10px" }}
              />
              <Skeleton height={12} width={120} />{" "}
            </div>
            <Skeleton height={12} width={"100%"} />{" "}
            <Skeleton height={12} width={"70%"} />
          </div>
        </div>
      </SkeletonTheme>{" "}
      <SkeletonTheme baseColor={getBase}>
        <div className="p-2">
          <div
            style={{  borderRadius: "5px" }}
            className="skeletion-new p-3"
          >
            <div className="d-flex mb-1">
              <Skeleton
                circle
                height={12}
                width={12}
                style={{ marginRight: "10px" }}
              />
              <Skeleton height={12} width={120} />{" "}
            </div>
            <Skeleton height={12} width={"100%"} />{" "}
            <Skeleton height={12} width={"70%"} />
          </div>
        </div>
      </SkeletonTheme>{" "}
      <SkeletonTheme baseColor={getBase}>
        <div className="p-2">
          <div
            style={{ borderRadius: "5px" }}
            className="skeletion-new p-3"
          >
            <div className="d-flex mb-1">
              <Skeleton
                circle
                height={12}
                width={12}
                style={{ marginRight: "10px" }}
              />
              <Skeleton height={12} width={120} />{" "}
            </div>
            <Skeleton height={12} width={"100%"} />{" "}
            <Skeleton height={12} width={"70%"} />
          </div>
        </div>
      </SkeletonTheme>{" "}
    </div>
  );
};

export default Skeletion;
