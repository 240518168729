import React, { useContext, useEffect, useState } from "react";
import LayoutNew from "../components/shared/LayoutNew";
import MainBanner from "../pages/slider/MainBanner";
import Casino from "../pages/casino-home/Casino";
import SportAccordian from "../components/SportAccordian";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import moment from "moment";
import helpers from "../Utils/helpers";
import NoEvent from "../components/NoEvent";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import { AiFillStar } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { BiSortAlt2 } from "react-icons/bi";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CurrentNews from "../components/CurrentNews";
import jwt_decode from "jwt-decode";
import GameMenu from "../components/GameMenu";
import logo from "../assets/images/loader.gif";

import {
  FaSearch,
  FaBell,
  FaCog,
  FaTv,
  FaAngleLeft,
  FaCheckCircle,
} from "react-icons/fa";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import { io } from "socket.io-client";
import Favourite from "./Favourtie";
import ByTimeSports from "../components/ByTimeSports";
import Skeletion from "../components/Skeletion";
import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
import { Fade } from "react-awesome-reveal";
import { GiSoccerBall, GiTennisBall } from "react-icons/gi";
const Home = () => {
  const {
    setGameTab,
    gameTab,
    keyTime,
    setKeyTime,
    user,
    profileData,
    setSearchTab,
    searchTab,
    parly,
    setParly,
    messagelist,
    setAnnouncement,
  } = useContext(AuthContext);
  const customAnimation = keyframes`
  from {
    opacity: 0;
    background:black
  }

  to {
    opacity: 1; 
    background:black
  }
`;
  let navigate = useNavigate();
  const [tabBy, setTabBy] = useState("time");
  const [byTime, setByTime] = useState([]);
  const { setLimit } = useContext(BetSlipContext);
  const [list, setList] = useState([]);
  const [newResult, setNewResult] = useState([]);
  const [show, setShow] = useState(false);
  const [activeKeys, setActiveKeys] = useState([0]);
  const [updatedList, setUpdatedList] = useState([]);
  const [activeFav, setActiveFav] = useState([]);
  const [odds, setOdds] = useState([]);
  const [selected, setSelected] = useState({});
  const [loader, setLoader] = useState(false);
  const [eventIdPremium, setEventIdPremium] = useState([]);
  const [allShow, setAllShow] = useState({
    cricket: false,
    tennis: false,
    soccer: false,
  });

  const [updated, setUpdated] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const [activeKeysAll, setActiveKeysAll] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });

  const [cricketScore, setCricketScore] = useState([]);
  const scoreCricketAll = (e) => {
    setCricketScore(e);
  };
  const getData = async () => {
    setLoader(true);
    const { status, data } = await apiGet(apiPath.seriesList, {
      gameType: gameTab == "all" ? "all" : gameTab,
      type: keyTime == 'active' ? 'today' : keyTime,
      userId: !isEmpty(localStorage.getItem("token"))
        ? jwt_decode(localStorage.getItem("token"))?.user?._id
        : "",
    });
    if (data?.success) {
      if (gameTab == "all") {
        let obj = {
          cricket:
            filteredArray(
              data?.results?.filter((res) => res?.gameType == "cricket")
            ) || [],
          tennis:
            filteredArray(
              data?.results?.filter((res) => res?.gameType == "tennis")
            ) || [],
          soccer:
            filteredArray(
              data?.results?.filter((res) => res?.gameType == "soccer")
            ) || [],
        };
        setUpdated(obj);
        setActiveKeysAll({
          cricket:
            obj?.cricket?.length > 0
              ? obj?.cricket
                  ?.filter((res) => {
                    return res?.hasMatchOddsInList;
                  })
                  ?.map((res) => {
                    return res?.seriesId;
                  })
              : [],
          soccer:
            obj?.soccer?.length > 0
              ? obj?.soccer
                  ?.filter((res) => {
                    return res?.hasMatchOddsInList;
                  })
                  ?.map((res) => {
                    return res?.seriesId;
                  })
              : [],
          tennis:
            obj?.tennis?.length > 0
              ? obj?.tennis
                  ?.filter((res) => {
                    return res?.hasMatchOddsInList;
                  })
                  ?.map((res) => {
                    return res?.seriesId;
                  })
              : [],
        });
      } else {
        let temp = data?.results
          ?.filter((res) => {
            return res?.hasMatchOddsInList;
          })
          ?.map((res) => {
            return res?.seriesId;
          });
        setActiveKeys(temp);
      }
      setActiveFav(
        data?.results
          ?.map((res) => {
            return res?.matchList;
          })
          .flat()
          ?.filter((res) => {
            return res?.isFavourite;
          })
          ?.map((res) => {
            return res?.eventId;
          })
      );
      setList(filteredArray(data?.results));
      let newResult = data?.results
        ?.map((res) => {
          return res?.matchList;
        })
        .flat();
      setNewResult(newResult);
      setEventIdPremium(
        newResult?.map((res) => {
          return res?.eventId;
        })
      );

      setLoader(false);
    }
  };

  const filteredArray = (array) => {
    if (array?.length > 0) {
      let check = array?.filter((res) => {
        return res?.seriesName == "Others";
      });
      if (check?.length > 1) {
        let find = check?.find((res) => {
          return res?.seriesName == "Others";
        });
        return [
          ...array?.filter((res) => {
            return res?.seriesName !== "Others";
          }),
          {
            ...find,
            matchList: check
              ?.map((res) => {
                return res?.matchList;
              })
              .flat(),
          },
        ];
      } else {
        return array;
      }
    }
  };

  const leagueData = async () => {
    const { status, data } = await apiGet(
      apiPath.seriesList + "?gameType=" + gameTab + "&domain=bajifair.live"
    );
    if (data?.success) {
      setList(data?.results);
      setUpdatedList(() => {
        setGameTab(gameTab);
        return filteredArray(
          data?.results?.filter((res) => {
            return res?.gameType == gameTab;
          })
        );
      });
    }
  };

  useEffect(() => {
    if (tabBy == "time") {
      setByTime(
        list
          ?.map((res) => {
            return res?.matchList;
          })
          .flat()
          .sort((a, b) => new Date(a.eventDateTime) - new Date(b.eventDateTime))
      );
    }
  }, [tabBy, list]);

  useEffect(() => {
    if (keyTime == "leagues") {
      leagueData();
    } else {
      setList([]);
      getData();
    }
  }, [gameTab, keyTime]);

  const handleSelect = (event, type) => {
    if (gameTab !== "all") {
      if (activeKeys.includes(event)) {
        setActiveKeys(activeKeys.filter((res, index) => res !== event));
      } else {
        setActiveKeys([...activeKeys, event]);
      }
    } else {
      updateActive(event, type);
    }
  };
  const obj = {
    cricket: activeKeysAll?.cricket || [],
    tennis: activeKeysAll?.tennis || [],
    soccer: activeKeysAll?.soccer || [],
  };
  const updateActive = (event, type) => {
    if (obj[type].includes(event)) {
      if (type == "cricket") {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: obj[type].filter((res, index) => res !== event),
        });
      } else if (type == "tennis") {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: obj[type].filter((res, index) => res !== event),
        });
      } else if (type == "soccer") {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: obj[type].filter((res, index) => res !== event),
        });
      }
    } else {
      if (type == "cricket") {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: [...activeKeysAll?.cricket, event],
        });
      } else if (type == "tennis") {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: [...activeKeysAll?.tennis, event],
        });
      } else if (type == "soccer") {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: [...activeKeysAll?.soccer, event],
        });
      }
    }
  };

  const handelChanges = (type) => {
    // let temp = show ? false : true;
    // setShow(temp);
    // if (temp) {
    //   if (gameTab !== "all") {
    //     setActiveKeys(
    //       list?.map((res, index) => {
    //         return res?.seriesId;
    //       })
    //     );
    //   }
    // } else {
    //   setActiveKeys([]);
    // }
    if (allShow[type]) {
      setActiveKeysAll((prev) => {
        return {
          ...prev,
          [type]:
            updated[type]?.length > 0
              ? updated[type]?.map((res) => {
                  return res?.seriesId;
                })
              : [],
        };
      });
    } else {
      setActiveKeysAll((prev) => {
        return {
          ...prev,
          [type]: [],
        };
      });
    }
    setAllShow((prev) => {
      return { ...prev, [type]: !prev[type] };
    });
  };

  const getMatchOddsMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds +
        `?marketId=${id
          ?.map((res) => {
            return res?.marketId;
          })
          ?.join(",")}&multi=true&eventIds=${id
          ?.map((res) => {
            return res?.eventId;
          })
          ?.join(",")}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setOdds(socket_data);
      }
    }
  };

  useEffect(() => {
    if (newResult?.length > 0 && !parly) {
      getMatchOddsMulti(newResult);
    }
  }, [newResult]);

  const [multiOddsPremium, setMultiOddsPremium] = useState([]);
  const getPremiumMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getPremiumFancy + `?events=${id?.join(",")}&parly=true&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setMultiOddsPremium(socket_data);
      }
    }
  };

  useEffect(() => {
    if (eventIdPremium?.length > 0 && parly) {
      getPremiumMulti(eventIdPremium);
    }
  }, [eventIdPremium, parly]);

  
  useEffect(() => {
    if (eventIdPremium?.length > 0) {
      let interval = setInterval(() => {
        if (parly) {
          getPremiumMulti(eventIdPremium);
        }
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [eventIdPremium, parly]);

  // Socket
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const getSocket = () => {
    if (checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?user_id=${randomId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("scoreCricketAll", scoreCricketAll);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  useEffect(() => {
    if (newResult?.length > 0) {
      let interval = setInterval(() => {
        if (!parly) {
          getMatchOddsMulti(newResult);
        }
      }, 1500);
      return () => {
        clearInterval(interval);
      };
    }
  }, [newResult, parly]);

  const [count, setCount] = useState({
    cricketInplayCount: 0,
    soccerInplayCount: 0,
    tennisInplayCount: 0,
  });

  const inPlayCount = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.inPlayCountMobile,
      {
        type: keyTime,
      }
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setCount(response_users.results);
        }
      }
    }
  };
  useEffect(() => {
    inPlayCount();
  }, [keyTime]);

  const handelChangesAll = () => {
    if (!show) {
      setActiveKeys(
        list?.map((res) => {
          return res?.seriesId;
        })
      );
      setShow(true);
    } else {
      setShow(false);
      setActiveKeys([]);
    }
  };
  useEffect(() => {
    if(gameTab=="casino"){
      setGameTab("all")
    }else{
      setGameTab(gameTab)
    }
   
  }, [gameTab]);
  
  // console.log("keyTime",keyTime,gameTab);
    // console.log("---------->",gameTab,selected,updatedList)
  // console.log("updated?.cricket",updated?.cricket,byTime)
  return (
    <LayoutNew visibilityType={true}>
      {searchTab ? (
        <SearchBar />
      ) : (
        <>
          {/* <CurrentNews
            message={messagelist}
            setAnnouncement={setAnnouncement}
          /> */}

          <div class="ftype d-flex justify-content-between">
            <ul>
              <li
                className={keyTime == "in_play" ? "active" : ""}
                onClick={() => {
                  setKeyTime("in_play");
                  setParly(false);
                }}
              >
                <span class="left-view">
                  <svg
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                    _mstVisible="6"
                  >
                    <title _mstHash="188" _mstVisible="7">
                      tab-radius-l
                    </title>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="true"
                      class="tab-bg"
                      _mstVisible="7"
                    >
                      <path
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-l"
                        transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                        _mstVisible="8"
                      ></path>
                    </g>
                  </svg>
                </span>
                Live{" "}
                <span class="right-view">
                  <svg
                    data-v-16f79fce=""
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                  >
                    <title data-v-16f79fce="">tab-radius-r</title>
                    <g
                      data-v-16f79fce=""
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="false"
                      class="tab-bg"
                    >
                      <path
                        data-v-16f79fce=""
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-r"
                      ></path>
                    </g>
                  </svg>
                </span>
              </li>
              <li
                className={keyTime == "active" ? "active" : ""}
                onClick={() => {
                  setGameTab("all");
                  setKeyTime("active");
                  setParly(false);
                }}
              >
                <span class="left-view">
                  <svg
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                    _mstVisible="6"
                  >
                    <title _mstHash="188" _mstVisible="7">
                      tab-radius-l
                    </title>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="true"
                      class="tab-bg"
                      _mstVisible="7"
                    >
                      <path
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-l"
                        transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                        _mstVisible="8"
                      ></path>
                    </g>
                  </svg>
                </span>{" "}
                Upcoming
                <span class="right-view">
                  <svg
                    data-v-16f79fce=""
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                  >
                    <title data-v-16f79fce="">tab-radius-r</title>
                    <g
                      data-v-16f79fce=""
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="false"
                      class="tab-bg"
                    >
                      <path
                        data-v-16f79fce=""
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-r"
                      ></path>
                    </g>
                  </svg>
                </span>
              </li>
              {!parly && (
                <li
                  className={keyTime == "leagues" ? "active" : ""}
                  onClick={() => {
                    setKeyTime("leagues");
                    setGameTab("cricket");
                    setParly(false);
                  }}
                >
                  <span class="left-view">
                    <svg
                      width="8px"
                      height="8px"
                      viewBox="0 0 8 8"
                      version="1.1"
                      class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                      _mstVisible="6"
                    >
                      <title _mstHash="188" _mstVisible="7">
                        tab-radius-l
                      </title>
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        data-is-selected="true"
                        class="tab-bg"
                        _mstVisible="7"
                      >
                        <path
                          d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                          id="tab-radius-l"
                          transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                          _mstVisible="8"
                        ></path>
                      </g>
                    </svg>
                  </span>{" "}
                  Leagues{" "}
                  <span class="right-view">
                    <svg
                      data-v-16f79fce=""
                      width="8px"
                      height="8px"
                      viewBox="0 0 8 8"
                      version="1.1"
                      class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                    >
                      <title data-v-16f79fce="">tab-radius-r</title>
                      <g
                        data-v-16f79fce=""
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        data-is-selected="false"
                        class="tab-bg"
                      >
                        <path
                          data-v-16f79fce=""
                          d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                          id="tab-radius-r"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
              )}

              <li
                onClick={() => {
                  if (isEmpty(user)) {
                    navigate("/login");
                  } else {
                    setParly(!parly);
                  }
                }}
                className={parly ? "parlyatab active" : "parlyatab"}
              >
                <span class="left-view">
                  <svg
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                    _mstVisible="6"
                  >
                    <title _mstHash="188" _mstVisible="7">
                      tab-radius-l
                    </title>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="true"
                      class="tab-bg"
                      _mstVisible="7"
                    >
                      <path
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-l"
                        transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                        _mstVisible="8"
                      ></path>
                    </g>
                  </svg>
                </span>{" "}
                {parly ? <i class="icon-check"></i> : ""} Parlay
                <span class="right-view">
                  <svg
                    data-v-16f79fce=""
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                  >
                    <title data-v-16f79fce="">tab-radius-r</title>
                    <g
                      data-v-16f79fce=""
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      data-is-selected="false"
                      class="tab-bg"
                    >
                      <path
                        data-v-16f79fce=""
                        d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                        id="tab-radius-r"
                      ></path>
                    </g>
                  </svg>
                </span>
              </li>
            </ul>
            <div className="settingtab d-flex">
              <span>
                <FaSearch
                  onClick={() => setSearchTab(true)}
                  className="ms-auto"
                />
              </span>
              <span>
                <FaBell className="ms-auto" onClick={() => {
          
            setAnnouncement(true);
           
        }}/>
              </span>
              <span>
                <FaCog className="ms-auto" />
              </span>
            </div>
          </div>
          <GameMenu
            count={count}
            game={gameTab}
            type="home"
            setGame={setGameTab}
            keyTime={keyTime}
          />
          <div className="all-complete">
            <div className="d-flex w-100">
              <DropdownButton
                id="abc"
                title={
                  <div>
                    <BiSortAlt2 className="me-1" />
                    {tabBy == "comp" ? "by Competition" : "by Time"}
                  </div>
                }
                className=""
              >
                <Dropdown.Item
                  onClick={() => {
                    setTabBy(tabBy == "comp" ? "time" : "comp");
                    setParly(false);
                  }}
                >
                  {tabBy !== "comp" ? "by Competition" : "by Time"}
                </Dropdown.Item>
              </DropdownButton>
              {["active", "tomorrow"].includes(keyTime) && (
                <div class="d-flex align-items-center all-complete-new">
                  <div
                    onClick={() => setKeyTime("active")}
                    className="d-flex align-items-center"
                    style={{ borderRight: "1px solid black", padding: "0 6px" }}
                  >
                    {keyTime == "active" && (
                      <FaCheckCircle
                        color="#064b4d"
                        size={12}
                        style={{ marginRight: "3px" }}
                      />
                    )}
                    <span class="">Today</span>
                  </div>
                  <div
                    onClick={() => setKeyTime("tomorrow")}
                    className="d-flex align-items-center"
                    style={{ padding: "0 6px" }}
                  >
                    {" "}
                    {keyTime == "tomorrow" && (
                      <FaCheckCircle
                        color="#064b4d"
                        size={12}
                        style={{ marginRight: "3px" }}
                      />
                    )}
                    <span class="">Tomorrow</span>
                  </div>
                </div>
              )}
            </div>
            {gameTab !== "all" && tabBy == "comp" && (
              <div
                onClick={() => {
                  handelChangesAll();
                }}
                class="all-complete-all"
              >
                <span class="mr-1">Collapse</span>
                {!show ? (
                  <MdKeyboardDoubleArrowDown />
                ) : (
                  <MdKeyboardDoubleArrowUp />
                )}
              </div>
            )}
          </div>
          {loader ? (
            <Fade cascade duration={200}>
              <Skeletion />
            </Fade>
          ) : gameTab == "myFav" ? (
            <Favourite />
          ) : gameTab == "all" && keyTime !== "leagues" ? (
            
            <Fade triggerOnce cascade duration={800}>
              {updated?.cricket?.length > 0 && (
                <div className="bg-skylight p-3 px-sm-2 pt-1 px-1">
                  <div>
                    <div
                      style={{
                        
                        padding: "5px",
                        marginBottom: "3px",
                      }}
                      className="d-flex justify-content-between align-items-center bg-skydark"
                    >
                      <h6
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "0",
                        }}
                        className="sports-heading m-0"
                      >
                        {" "}
                        <i
                          class="icon-cricket"
                          style={{ fontSize: `17px`, marginRight: "6px" }}
                        ></i>
                        Cricket
                      </h6>
                      {tabBy == "comp" && (
                        <div
                          onClick={() => handelChanges("cricket")}
                          class="all-complete-all"
                        >
                          <span class="mr-1">ALL</span>
                          {!allShow?.cricket ? (
                            <MdKeyboardDoubleArrowDown />
                          ) : (
                            <MdKeyboardDoubleArrowUp />
                          )}
                        </div>
                      )}
                    </div>
                    {updated?.cricket?.length > 0
                      ? tabBy == "comp"
                        ? updated?.cricket?.map((item, index) => {
                            return (
                              <SportAccordian
                                profileData={profileData}
                                activeKeys={activeKeysAll?.cricket}
                                handleSelect={handleSelect}
                                user={user}
                                showOdds={parly ? false : true}
                                type="cricket"
                                activeFav={activeFav}
                                odds={odds}
                                setActiveFav={setActiveFav}
                                keyTime={keyTime}
                                mainList={updated}
                                item={item}
                                index={index}
                                setLimit={setLimit}
                                score={[...score, ...cricketScore]}
                                multiOddsPremium={multiOddsPremium}
                              />
                            );
                          })
                        : byTime?.map((item, index) => {
                          let matchOdds = odds?.find((res) => res?.mi === item?.marketId) || {};
                        
                          if (item?.eventType === "4") {
                            return (
                              <ByTimeSports
                                profileData={profileData}
                                user={user}
                                keyTime={keyTime}
                                activeKeys={activeKeys}
                                handleSelect={handleSelect}
                                activeFav={activeFav}
                                setActiveFav={setActiveFav}          object={item}
                                odds={odds}
                                mainList={list}
                                type="all"
                                showOdds={
                                  parly
                                    ? false
                                    : gameTab === "all"
                                    ? false
                                    : true
                                }
                                item={item}
                                index={index}
                                matchOdds={matchOdds}
                                setLimit={setLimit}
                                score={[...score, ...cricketScore]}
                                multiOddsPremium={multiOddsPremium}
                              />
                            );
                          }
                        
                          return null; // Return null if the condition is not met
                        })
                      : ""}
                  </div>
                </div>
              )}
              {updated?.soccer?.length > 0 && (
                <div className="bg-skylight p-3 px-sm-2 pt-1 px-1">
                  <div>
                    <div
                      style={{
                        
                        padding: "5px",
                        marginBottom: "3px",
                      }}
                      className="d-flex justify-content-between align-items-center bg-skydark"
                    >
                      <h6
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "0",
                        }}
                        className="sports-heading m-0"
                      >
                        {" "}
                        <GiSoccerBall
                          size={20}
                          style={{ marginRight: "6px" }}
                        />{" "}
                        Soccer
                      </h6>
                      {tabBy == "comp" && (
                        <div
                          onClick={() => handelChanges("soccer")}
                          class="all-complete-all"
                        >
                          <span class="mr-1">ALL</span>
                          {!allShow?.soccer ? (
                            <MdKeyboardDoubleArrowDown />
                          ) : (
                            <MdKeyboardDoubleArrowUp />
                          )}
                        </div>
                      )}
                    </div>
                    {updated?.soccer?.length > 0
                      ? tabBy == "comp"
                        ? updated?.soccer?.map((item, index) => {
                            return (
                              <SportAccordian
                                profileData={profileData}
                                user={user}
                                keyTime={keyTime}
                                activeFav={activeFav}
                                setActiveFav={setActiveFav}
                                activeKeys={activeKeysAll?.soccer}
                                odds={odds}
                                mainList={updated}
                                type="soccer"
                                handleSelect={handleSelect}
                                item={item}
                                index={index}
                                setLimit={setLimit}
                                score={[...score, ...cricketScore]}
                                multiOddsPremium={multiOddsPremium}
                              />
                            );
                          })
                        : byTime?.map((item, index) => {
                            let matchOdds =
                              odds?.find((res) => {
                                return res?.mi == item?.marketId;
                              }) || {};
                              if (item?.eventType === "1") {
                            return (
                              <ByTimeSports
                                profileData={profileData}
                                user={user}
                                keyTime={keyTime}
                                activeKeys={activeKeys}
                                handleSelect={handleSelect}
                                activeFav={activeFav}
                                setActiveFav={setActiveFav}
                                odds={odds}
                                mainList={list}          object={item}
                                type="all"
                                showOdds={
                                  parly
                                    ? false
                                    : gameTab == "all"
                                    ? false
                                    : true
                                }
                                item={item}
                                index={index}
                                matchOdds={matchOdds}
                                setLimit={setLimit}
                                score={[...score, ...cricketScore]}
                                multiOddsPremium={multiOddsPremium}
                              />
                            );}
                          })
                      : ""}
                  </div>
                </div>
              )}{" "}
              {updated?.tennis?.length > 0 && (
                <div className="bg-skylight p-3 px-sm-2 pt-1 px-1">
                  <div>
                    <div
                      style={{
                        
                        padding: "5px",
                        marginBottom: "3px",
                      }}
                      className="d-flex justify-content-between align-items-center bg-skydark"
                    >
                      <h6
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "0",
                        }}
                        className="sports-heading m-0"
                      >
                        {" "}
                        <GiTennisBall
                          size={20}
                          style={{ marginRight: "6px" }}
                        />
                        Tennis
                      </h6>
                      {tabBy == "comp" && (
                        <div
                          onClick={() => handelChanges("tennis")}
                          class="all-complete-all"
                        >
                          <span class="mr-1">ALL</span>
                          {!allShow?.tennis ? (
                            <MdKeyboardDoubleArrowDown />
                          ) : (
                            <MdKeyboardDoubleArrowUp />
                          )}
                        </div>
                      )}
                    </div>
                    {updated?.tennis?.length > 0
                      ? tabBy == "comp"
                        ? updated?.tennis?.map((item, index) => {
                            return (
                              <SportAccordian
                                profileData={profileData}
                                user={user}
                                keyTime={keyTime}
                                activeFav={activeFav}
                                setActiveFav={setActiveFav}
                                activeKeys={activeKeysAll?.tennis}
                                odds={odds}
                                mainList={updated}
                                type="tennis"
                                handleSelect={handleSelect}
                                item={item}
                                index={index}
                                setLimit={setLimit}
                                score={[...score, ...cricketScore]}
                                multiOddsPremium={multiOddsPremium}
                              />
                            );
                          })
                        : byTime?.map((item, index) => {
                            let matchOdds =
                              odds?.find((res) => {
                                return res?.mi == item?.marketId;
                              }) || {};
                              if (item?.eventType === "2") {
                            return (
                              <ByTimeSports
                                profileData={profileData}
                                user={user}
                                keyTime={keyTime}
                                activeKeys={activeKeys}
                                handleSelect={handleSelect}
                                activeFav={activeFav}
                                setActiveFav={setActiveFav}
                                odds={odds}
                                mainList={list}          object={item}
                                type="all"
                                showOdds={
                                  parly
                                    ? false
                                    : gameTab == "all"
                                    ? false
                                    : true
                                }
                                item={item}
                                index={index}
                                matchOdds={matchOdds}
                                setLimit={setLimit}
                                score={[...score, ...cricketScore]}
                                multiOddsPremium={multiOddsPremium}
                              />
                            );}
                          })
                      : ""}
                  </div>
                </div>
              )}
              {updated?.tennis?.length == 0 &&
                updated?.soccer?.length == 0 &&
                updated?.cricket?.length == 0 && <NoEvent />}
            </Fade>
          ) : keyTime !== "leagues" ? (
            <>
              <Fade triggerOnce cascade duration={800}>
                <div className="bg-skylight p-3 px-sm-2 pt-1 px-1">
                  <div>
                    {list?.length > 0 ? (
                      tabBy == "comp" ? (
                        list?.map((item, index) => {
                          return (
                            <SportAccordian
                              profileData={profileData}
                              user={user}
                              keyTime={keyTime}
                              activeKeys={activeKeys}
                              handleSelect={handleSelect}
                              type={gameTab}
                              showOdds={
                                parly ? false : gameTab == "all" ? false : true
                              }
                              gameTab={gameTab}
                              activeFav={activeFav}
                              setActiveFav={setActiveFav}
                              odds={odds}
                              item={item}
                              index={index}
                              setLimit={setLimit}
                              score={[...score, ...cricketScore]}
                              multiOddsPremium={multiOddsPremium}
                            />
                          );
                        })
                      ) : (
                        byTime?.map((item, index) => {
                          let matchOdds =
                            odds?.find((res) => {
                              return res?.mi == item?.marketId;
                            }) || {};
                          return (
                            <ByTimeSports
                              profileData={profileData}
                              user={user}
                              keyTime={keyTime}
                              activeKeys={activeKeys}
                              handleSelect={handleSelect}
                              activeFav={activeFav}
                              setActiveFav={setActiveFav}
                              odds={odds}
                              mainList={list}
                              type="all"
                              object={item}
                              showOdds={
                                parly ? false : gameTab == "all" ? false : true
                              }
                              item={item}
                              // eventId={eventId}
                              matchOdds={matchOdds}
                              index={index}
                              setLimit={setLimit}
                              // score={scoreAll}
                              multiOddsPremium={multiOddsPremium}
                            />
                          );
                        })
                      )
                    ) : (
                      <NoEvent />
                    )}
                  </div>
                </div>
              </Fade>
            </>
          ) : (
         
            isEmpty(selected) && (
              <div>
                {updatedList?.length > 0 ? (
                  <div className="d-flex p-3 leage-list main-casino-wrapper">
                    
                    <div
                      className={`main-list w-100 ${
                        updatedList?.length > 7 ? "mb-5" : ""
                      }`}
                    >
                      <h6 className="mb-3">Popular</h6>
                      {updatedList?.map((item, index) => {
                        return (
                          <div className="w-100 mb-1 match-list">
                            <ul className="p-0 m-0">
                              <li onClick={() => setSelected(item)}>
                                <i class="icon-top"></i> {item?.seriesName}
                              </li>
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex p-3 justify-content-center align-items-center leage-list main-casino-wrapper">
                    <NoEvent />
                  </div>
                )}
              </div>
            )
          )}
          {!isEmpty(selected) && (
            <div className="second-level-leage">
              <div className="balance-label position-relative text-start ps-5">
                <button
                  onClick={() => setSelected({})}
                  className="bg-transparent border-0 text-white position-absolute start-0 pt-0"
                >
                  <FaAngleLeft className="fs-3" />
                </button>
                {selected?.seriesName}
              </div>

              <div className="d-flex p-3 px-4 leage-list main-casino-wrapper">
                <div
                  className={`main-list w-100 ${
                    selected?.matchList?.length > 7 ? "mb-5" : ""
                  }`}
                >
                  {selected?.matchList?.map((item, index) => {
                    return (
                      <div className="w-100 mb-3 match-list">
                        <ul className="p-0 m-0">
                          <li
                            onClick={() =>
                              navigate(
                                `/full-market/${item?.marketId}/${item?.eventId}`
                              )
                            }
                          >
                            {item?.runners?.map((res) => {
                              return (
                                <span className="d-block">
                                  {res?.RunnerName}
                                </span>
                              );
                            })}
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </LayoutNew>
  );
};

export default Home;

// <div>
//   <div className="bkash-loader apiloader">
//     <img
//       src={logo}
//       loop
//       autoPlay
//       muted
//       style={{ height: `150px` }}
//     />
//   </div>
// </div>
